var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MobileScreen',{attrs:{"title":_vm.$vuetify.lang.t('$vuetify.dashboard.currency_converter.title'),"icon":"$arrowsExchange"}},[_c('v-form',{key:_vm.formKey,ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('div',[_c('p',{staticClass:"text-caption mb-1 ml-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.dashboard.currency_converter.from_currency" ))+" ")]),_c('BaseDropDown',{attrs:{"items":_vm.fromCurrencyItems,"menu-props":{ offsetY: true, closeOnClick: true },"outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center text-no-wrap content-disabled"},[_c('BaseImg',{staticClass:"flex-shrink-0",attrs:{"src":_vm.getCurrencyIcon(_vm.fromCurrency)}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.fromCurrency)+", "+_vm._s(_vm.getSymbolCurrency(_vm.fromCurrency))+" ")])],1)]},proxy:true}]),model:{value:(_vm.fromCurrency),callback:function ($$v) {_vm.fromCurrency=$$v},expression:"fromCurrency"}})],1),_c('v-btn',{staticClass:"pa-0 d-flex mx-auto rounded-lg",style:({
        transform: 'rotate(90deg) translateX(9px)',
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.10)',
      }),attrs:{"x-small":"","height":"36","width":"36","color":"white"},on:{"click":_vm.reverseSenderAndRecipient}},[_c('v-icon',{attrs:{"size":"30","color":"blue-grey darken-4"}},[_vm._v(" $arrowsExchange ")])],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"text-caption mb-1 ml-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.currency_converter.to_currency"))+" ")]),_c('BaseDropDown',{attrs:{"items":_vm.toCurrencyItems,"menu-props":{ offsetY: true, closeOnClick: true },"outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center text-no-wrap content-disabled"},[_c('BaseImg',{staticClass:"flex-shrink-0",attrs:{"src":_vm.getCurrencyIcon(_vm.toCurrency)}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.toCurrency)+", "+_vm._s(_vm.getSymbolCurrency(_vm.toCurrency))+" ")])],1)]},proxy:true}]),model:{value:(_vm.toCurrency),callback:function ($$v) {_vm.toCurrency=$$v},expression:"toCurrency"}})],1),_c('div',[_c('p',{staticClass:"text-caption mb-1 ml-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.currency_converter.amount"))+" ")]),_c('v-text-field',{staticClass:"autoheight-details",attrs:{"rules":[
          _vm.fieldRules.required,
          _vm.fieldRules.positiveNumber,
          _vm.fieldRules.amount,
        ],"outlined":"","dense":"","inputmode":"decimal"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('p',{staticClass:"black--text mb-0 mt-1 text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getSymbolCurrency(_vm.fromCurrency))+" ")])]},proxy:true}]),model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"text-caption mb-1 ml-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.dashboard.currency_converter.receive_amount" ))+" ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('p',{staticClass:"black--text mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm.getSymbolCurrency(_vm.toCurrency))+" ")])]},proxy:true}]),model:{value:(_vm.receiveAmount),callback:function ($$v) {_vm.receiveAmount=$$v},expression:"receiveAmount"}})],1),_c('p',{class:[
        'text-caption',
        'text-center',
        'blue-grey--text',
        'text--darken-1',
        'mb-0',
        { 'visibility-hidden': _vm.exchangeRates.lifetimeSec <= 0 },
      ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.dashboard.currency_converter.exchange_rate_valid", _vm.exchangeRates.lifetimeSec ))+" ")]),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInsufficientBalance),expression:"isInsufficientBalance"}]},[_c('p',{staticClass:"text-caption d-flex align-center justify-center pt-3 mb-0 error--text font-weight-medium"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16","color":"error"}},[_vm._v(" $danger ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.errors.insufficient_balance"))+" ")],1)])]),_c('v-btn',{staticClass:"mt-3",attrs:{"loading":_vm.loadingExchangeRates,"disabled":_vm.loadingExchangeRates || !_vm.validForm,"block":"","type":"submit","color":"primary","height":"40"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.currency_converter.convert"))+" ")])],1),_c('ConfirmDialog',{attrs:{"loading":_vm.loadingExchangeFundsWallet},on:{"confirm":_vm.exchangeFundsWallet},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            '$vuetify.dashboard.currency_converter.are_you_sure',
            _vm.formatMoney({
              value: _vm.amount,
              currency: _vm.fromCurrency,
            }),
            _vm.formatMoney({
              value: _vm.receiveAmount,
              currency: _vm.toCurrency,
            })
          )
        )}})]},proxy:true}]),model:{value:(_vm.isShowConfirmDialog),callback:function ($$v) {_vm.isShowConfirmDialog=$$v},expression:"isShowConfirmDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }